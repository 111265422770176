/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../../components/common/layout/Footer";
import { useSelector } from "react-redux";
import ImpNotice from "../../../components/corporate/recruitment/ImpNotice";
import Login from "../../../components/common/auth/Login";
import Register from "../../../components/common/auth/Register";
import ResetPassword from "../../../components/common/auth/ResetPassword";
import VerifyEmail from "../../../components/common/auth/VerifyEmail";
import ApplySuccess from "../../../components/corporate/recruitment/ApplySuccess";
import ApplyError from "../../../components/corporate/recruitment/ApplyError";
import ApplyCondition from "../../../components/corporate/profile/ApplyCondition";
import LanguageChange from "../../../components/common/layout/LanguageChange";
import NewPassword from "../../../components/common/auth/NewPassword";

const RecruitmentLayout = () => {
    const impNotice: any = useSelector((state: any) => state.data.impNotice);
    const loginOpen: boolean = useSelector((state: any) => state.data.loginOpen);
    const registerOpen: boolean = useSelector((state: any) => state.data.registerOpen);
    const resetPasswordOpen: boolean = useSelector((state: any) => state.data.resetPasswordOpen);
    const verifyEmailOpen: boolean = useSelector((state: any) => state.data.verifyEmailOpen);
    const newPasswordOpen: boolean = useSelector((state: any) => state.data.newPasswordOpen);
    const applySuccess: boolean = useSelector((state: any) => state.data.applySuccess);
    const applyError: boolean = useSelector((state: any) => state.data.applyError);
    const applyCondition: boolean = useSelector((state: any) => state.data.applyCondition);
    const langOpen: boolean = useSelector((state: any) => state.data.langOpen);
    const location = useLocation();

    useEffect(() => {
        if (loginOpen || registerOpen || resetPasswordOpen || verifyEmailOpen || applySuccess || applyError || applyCondition || impNotice || langOpen || newPasswordOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [loginOpen, registerOpen, resetPasswordOpen, verifyEmailOpen, applyError, applySuccess, applyCondition, impNotice, langOpen, newPasswordOpen]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            {impNotice && <ImpNotice />}
            {loginOpen && <Login type="recruitment" />}
            {registerOpen && <Register type="recruitment" />}
            {resetPasswordOpen && <ResetPassword type="recruitment" />}
            {newPasswordOpen && <NewPassword type="recruitment" />}
            {verifyEmailOpen && <VerifyEmail />}
            {applySuccess && <ApplySuccess />}
            {applyError && <ApplyError />}
            {applyCondition && <ApplyCondition />}
            {langOpen && <LanguageChange />}
            <Outlet />
            <Footer type="recruitment" />
        </>
    );
};

export default RecruitmentLayout;