/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import { CloseBtn, LeftArr, LoaderIcon, Warn } from "../svg/AllSvg";
import useAxios from "../../../shared/hooks/use-axios";
import { url } from "../../../shared/utils/url";
import { authActions } from "../../../shared/store/auth-Slice";
import SecondaryBtn from "../form/SecondaryBtn";
import { useNavigate, useParams } from "react-router-dom";
import OtpInput from "react-otp-input";

const VerifyEmail = ({ role }: any) => {
    const verifyEmailOpen: boolean = useSelector((state: any) => state.data.verifyEmailOpen);
    const [submit, setSubmit] = useState<boolean>(false);
    const [sendSubmit, setSendSubmit] = useState<boolean>(false);
    const [otp, setOtp] = useState<any>(null);
    const [error, setError] = useState<string>("");
    const [seconds, setSeconds] = useState<number>(29);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const ref: any = useRef();
    const params = useParams();
    const navigate = useNavigate();

    // for seconds count 
    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target) && role !== "admin") {
                dispatch(dataActions.setVerifyEmailOpen(false));
                localStorage.removeItem("email")
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27 && role !== "admin") {
            dispatch(dataActions.setVerifyEmailOpen(false))
            localStorage.removeItem("email")
        }
    };

    const handleSend = async () => {
        try {
            setSendSubmit(true)
            let obj: any = {};

            obj = {
                email: localStorage.getItem("email"),
                type: localStorage.getItem("auth_type")
            }

            const res: any = await useAxios.post(url?.sendOtp, obj);
            if (res && res?.status === 200) {
                setSeconds(29);
                setSendSubmit(false)
            }
        } catch (error: any) {
            if (error && error?.response?.status === 400) {
                setError({ ...error, email: error?.response?.data?.message })
            }
            setSendSubmit(false)
            console.error("error while send otp", error);
        }
    }

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: localStorage.getItem("email"),
        },
        validationSchema: yup.object().shape({
            email: yup.string().email(t("auth.form.validation.wrong_email")).required(t("auth.form.validation.email"))
        }),
        onSubmit: async (values: any) => {
            try {
                if (!!!otp) {
                    setError(t("auth.otp_err"));
                    return;
                }
                setSubmit(true)
                let obj = {
                    email: localStorage.getItem("email"),
                    type: localStorage.getItem("auth_type"),
                    otp: otp
                }
                const res: any = await useAxios.post(url?.verifyEmail, obj);

                if (res && res?.status === 200) {
                    if (localStorage.getItem("auth_type") === "registration") {
                        dispatch(authActions.login());
                        dispatch(authActions.userDetail(res?.data?.data))
                        let sessionTimeout: any = (new Date().getTime() + (86400000));
                        localStorage.setItem('userSessionTimeout', sessionTimeout);
                        dispatch(authActions.userDetail(res?.data?.data))
                        localStorage.setItem('userdetail', JSON.stringify(res?.data?.data));
                        localStorage.setItem('usertoken', res?.data?.data?.auth_token);
                        localStorage.removeItem("email")
                        localStorage.removeItem("auth_type")
                        !!params?.lang ? navigate(`/${params?.lang}/create-profile`) : navigate(`/create-profile`)
                        dispatch(dataActions.setVerifyEmailOpen(false))
                    } else {
                        dispatch(dataActions.setVerifyEmailOpen(false))
                        dispatch(dataActions.setNewPasswordOpen(true))
                        localStorage.removeItem("auth_type")
                    }
                    setSubmit(false)
                }
            } catch (error: any) {
                setSubmit(false)
                if (error && error?.response?.status === 400) {
                    setError(error?.response?.data?.message?.toString())
                }
                console.error("error while verify email", error);
            }
        }
    });

    const handleBack = () => {
        if (role === "admin") {
            dispatch(dataActions.setRegisterOpen(true));
            dispatch(dataActions.setVerifyEmailOpen(false));
            localStorage.removeItem("email")
            !!params?.lang ? navigate(`/${params?.lang}/admin/login`) : navigate(`/admin/login`)
        } else {
            localStorage.removeItem("email")
            dispatch(dataActions.setRegisterOpen(true));
            dispatch(dataActions.setVerifyEmailOpen(false));
        }
    }

    return (
        <>
            {verifyEmailOpen &&
                <div className={`fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto ${role === "admin" ? "bg-white" : "bg-[rgba(171,171,171,0.70)] backdrop-blur-[10px]"}`}>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className={`relative m-[15px] mm:mx-auto bg-white rounded-[15px] w-full xsm:w-[480px] h-full lg:min-h-[457px] ${role === "admin" ? "shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)]" : "shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12)"}`} ref={ref}>
                            <form className="flex flex-col relative w-full h-full items-start justify-center p-[20px] xlx:p-[24px] z-[1]">
                                <div className="flex w-full justify-between">
                                    <div className="flex flex-col w-full xlx:gap-[4px]">
                                        <div className="flex items-center gap-[4px] ml-[-10px]">
                                            <button className=" flex items-center justify-center w-[25px] h-[25px] rounded-full border border-white hover:border hover:border-[#ddd] focus:border focus:border-[#ddd] bg-white" type="button" onClick={handleBack}>
                                                <LeftArr />
                                            </button>
                                            <h3 className="font-bold text-primaryText">{t("auth.verifyTitle")}
                                            </h3>
                                        </div>
                                        <h6 className="text-blackDark font-medium">{t("auth.verifyDesc")}</h6>
                                    </div>
                                    {role !== "admin" && <button className="w-[23px] h-[23px] rounded-full" type="button" onClick={() => { dispatch(dataActions.setVerifyEmailOpen(false)); localStorage.removeItem("email") }}>
                                        <CloseBtn />
                                    </button>}
                                </div>

                                <div className="flex flex-col gap-[18px] pt-[20px] lg:pt-[33px] w-full">
                                    <div className="flex items-center justify-center w-full">
                                        <div className="flex flex-col gap-[4px] group-input">
                                            <OtpInput
                                                value={otp}
                                                onChange={(otp: any) => { setOtp(otp); setError(""); }}
                                                numInputs={6}
                                                shouldAutoFocus={true}
                                                renderInput={(props: any) => <input {...props} />}
                                            />
                                            {!!error && <span className="flex gap-[6px]"><Warn />
                                                <span className="text-danger text-xs font-medium">{error}</span></span>}
                                        </div>


                                    </div>
                                </div>

                                <SecondaryBtn title="Verify" css="w-full flex items-center justify-center uppercase !rounded-[10px] mt-[30px] font-bold" onClick={formik?.handleSubmit} disabled={submit} />

                                {seconds > 0 && <div className="flex items-center justify-center w-full pt-[15px]">
                                    <LoaderIcon />
                                </div>}

                                <div className="flex items-center justify-center w-full pt-[15px] mb-[100px] xsm:mb-[139px] xlx:mb-[135px]">
                                    <div className="text-[#606060] text-sm leading-snug font-normal">{t("auth.dont_otp")} &nbsp;<button className="text-primary text-sm leading-snug font-semibold focus:underline hover:underline disabled:opacity-[0.5] disabled:cursor-not-allowed" disabled={seconds !== 0 || sendSubmit} type="button" onClick={handleSend}>{t("auth.send_again")}</button></div>
                                </div>
                            </form>

                            <div className="absolute top-0 left-0 right-0 bottom-0 flex items-end justify-end">
                                <img src={require("../../../assets/images/reset-password-bg.png")} alt="reset-password-bg" className="rounded-b-[15px] w-full" />
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default VerifyEmail;