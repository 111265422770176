const PrimaryInput = ({ title, placeholder, value, onBlur, onChange, type, name, defaultValue, disabled, min, max, exceptThisSymbols, err }: any) => {
    return (
        <div className="flex flex-col gap-[4px]">
            <h6 className="text-blackDark font-medium">{title}</h6>
            <input className={`rounded-[6px]  h-[35px] xlx:h-[40px] px-[15px] text-sm leading-snug disabled:opacity-[0.7] hover:bg-white focus:bg-white ${err ? "bg-[#FFEAEA] border border-[#F00] text-danger placeholder:text-danger" : "bg-[#F5F5F5] border border-[#D7D8DD] disabled:bg-[#F5F5F5] text-blackDull placeholder:text-blackDull"}`} type={type} name={name} placeholder={placeholder} value={value} onBlur={onBlur} onChange={onChange} autoComplete="off" onFocus={(e: any) => e.target.addEventListener("wheel", function (e: any) { e.preventDefault() }, { passive: false })}
                onKeyDown={(e: any) => !!exceptThisSymbols && exceptThisSymbols.includes(e.key) && e.preventDefault()} defaultValue={defaultValue} disabled={disabled} min={min && min} max={max && max} />
        </div>
    );
};

export default PrimaryInput;