/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { CloseSec, FareBg } from "../../common/svg/AllSvg";
import { useTranslation } from "react-i18next";

const FirstLastTime = () => {
    const firstlastTime: boolean = useSelector((state: any) => state.data.firstlastTime);
    const ref: any = useRef();
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                dispatch(dataActions.setFirstlastTimeOpen(false));
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setFirstlastTimeOpen(false))
        }
    };

    return (
        <>
            {firstlastTime &&
                <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] mm:mx-auto w-full xlsm:w-[500px] xlx:w-[541px] h-full relative" ref={ref}>
                            <div style={{ background: "linear-gradient(90deg, #2F00FF66 -0.55%, #44B0FF66 22.86%, #8B44FF66 48.36%, #FF664466 73.33%, #EBFF7066 99.34%)" }} className="blur-[35px] absolute left-0 right-0 bottom-0 w-full xlsm:w-[505px] h-[524px] rotate-[180deg]"></div>

                            <div className="shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12) w-full h-full rounded-[20px] xlx:rounded-[25px] z-[1] relative">
                                <div className="w-full relative h-[58px] xlx:h-[66px] z-[2]">
                                    <div className="flex justify-end w-full h-full">
                                        <div className="w-[calc(100%-174px)] xlx:w-[calc(100%-200px)] rounded-t-[20px] xlx:rounded-t-[25px] h-full border-b-0 border-l-0 border border-[#E4E4E7] bg-white px-[18px] xlx:px-[25px] flex justify-end xs:justify-between gap-[10px] pt-[15px] xlx:pt-[21px]">
                                            <p className="text-blackDull font-semibold max-w-[252px] xs:block hidden">{t("passenger.home.first_last_time.desc")}</p>

                                            <button className="w-[21px] h-[21px] rounded-full" type="button" onClick={() => dispatch(dataActions.setFirstlastTimeOpen(false))}>
                                                <CloseSec />
                                            </button>
                                        </div>

                                    </div>

                                    {/* curve */}
                                    <div className="absolute left-[-10px] xlx:left-0 bottom-[-17px] xlx:bottom-[-20px]">
                                        <FareBg className="xlx:w-auto xlx:h-auto h-[75px] w-[210px]" />
                                    </div>

                                    <div className="absolute top-0 left-0 bottom-0 py-[12px] w-[174px] xlx:w-[200px] flex items-center justify-center xlx:justify-end pr-[12px] xlx:pr-[15px]">
                                        <div className="h-[35px] xlx:h-[40px] px-[24px] xlx:px-[28px] bg-secDarkRed text-white shadow-[0px_42.444px_61.308px_0px_rgba(0,0,0,0.08)] text-lg font-bold rounded-[20px] flex items-center justify-center">{t("passenger.home.first_last_time.title")}</div>
                                    </div>
                                </div>

                                <div className="w-full rounded-[20px] xlx:rounded-[25px] !rounded-tr-none bg-white border border-[#E4E4E7] h-[calc(100%-55px)] xlx:h-[calc(100%-64px)] border-t-0 px-[20px] xlx:px-[35px] py-[20px] xlx:py-[30px] relative">

                                    <p className="text-blackDull font-semibold max-w-[252px] xs:hidden block">{t("passenger.home.first_last_time.desc")}</p>

                                    <div style={{ background: "radial-gradient(166.13% 90.21% at 17.79% 50%, #FFF 0%, #F6FFC0 100%)" }} className="border border-[#E2E2E2] rounded-[20px] gap-y-[10px] xxs:py-0 py-[20px] xxs:h-[120px] xs:h-[155px] xlx:h-[165px] w-full flex items-center justify-between xsm:justify-center xxs:flex-row flex-col px-[20px] xs:px-[30px] xsm:gap-[45px] mt-[10px]">
                                        <img src={require("../../../assets/images/first-time.png")} alt="Fist Metro Timing" className="xs:w-auto xs:h-auto w-[100px] h-[100px]" />

                                        <div className="flex flex-col gap-[2px] xlx:gap-[4px]">
                                            <h5 className="text-blackDull font-bold">{t("passenger.home.first_last_time.first_time")}</h5>
                                            <h2 className="text-primaryText font-bold">{t("passenger.home.first_last_time.f_time")}</h2>
                                        </div>
                                    </div>

                                    <div style={{ background: "radial-gradient(166.13% 90.21% at 17.79% 50%, #FFF 0%, #C0E5FF 100%)" }} className="border border-[#E2E2E2] rounded-[20px] gap-y-[10px] xxs:py-0 py-[20px] xxs:h-[120px] xs:h-[155px] xlx:h-[165px] w-full mt-[16px] mb-[90px] rotate-[180deg]">
                                        <div className="flex items-center justify-between xsm:justify-center xxs:flex-row flex-col px-[20px] xs:px-[30px] xsm:gap-[45px] rotate-[180deg] w-full h-full">
                                            <div className="flex flex-col gap-[2px] xlx:gap-[4px]">
                                                <h5 className="text-blackDull font-bold">{t("passenger.home.first_last_time.last_time")}</h5>
                                                <h2 className="text-primaryText font-bold">{t("passenger.home.first_last_time.l_time")}</h2>
                                            </div>

                                            <img src={require("../../../assets/images/last-time.png")} alt="Last Metro Timing" className="xs:w-auto xs:h-auto w-[100px] h-[100px]" />
                                        </div>
                                    </div>


                                    <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full flex items-end justify-end">
                                        <img src={require("../../../assets/images/fare-bg.png")} alt="fare-bg" className="rounded-b-[20px] xlx:rounded-b-[25px] w-full opacity-[0.6]" />
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div >
                </div >}
        </>
    );
};

export default FirstLastTime;