/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import HiringLayout from "./HiringLayout";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
const Home = React.lazy(() => import("../../../pages/corporate/hiring/home/Home"));
const Login = React.lazy(() => import("../../../pages/corporate/hiring/auth/Login"));
const AllAds = React.lazy(() => import("../../../pages/corporate/hiring/hiring/AllAds"));
const CreateJob = React.lazy(() => import("../../../pages/corporate/hiring/hiring/CreateJob"));
const AdDetails = React.lazy(() => import("../../../pages/corporate/hiring/hiring/AdDetails"));
const PublishedSuccess = React.lazy(() => import("../../../pages/corporate/hiring/hiring/PublishedSuccess"));
const JobDetails = React.lazy(() => import("../../../pages/corporate/hiring/hiring/JobDetails"));
const CandidateDetail = React.lazy(() => import("../../../pages/corporate/hiring/hiring/CandidateDetail"));

const Hiring = () => {
    const adminIsLogin: boolean = useSelector((state: any) => state.auth.adminIsLogin);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const dispatch = useDispatch();

    useEffect(() => {
        let host: string[] = window.location.hostname.split(".");
        if (host?.length > 0) {
            dispatch(dataActions.setTenant(host?.includes("www") ? host[1] : host[0]))
        }
    }, [window.location.hostname])

    return (
        <>
            <Routes>
                {!adminIsLogin ? <>
                    <Route path="/admin/login" element={<Login />} />
                    <Route path="/:lang/admin/login" element={<Login />} />
                    <Route path="*" element={<Navigate to={`/${lang ? lang : "en"}/admin/login`} />} />
                </> :
                    <Route element={<HiringLayout />}>
                        <Route path="/admin/" element={<Home />} />
                        <Route path="/:lang/admin" element={<Home />} />
                        <Route path="/admin/ads" element={<AllAds />} />
                        <Route path="/:lang/admin/ads" element={<AllAds />} />
                        <Route path="/admin/ads/:id" element={<AdDetails />} />
                        <Route path="/:lang/admin/ads/:id" element={<AdDetails />} />
                        <Route path="/admin/job/:id" element={<JobDetails />} />
                        <Route path="/:lang/admin/job/:id" element={<JobDetails />} />
                        <Route path="/admin/ads/create-job/:id" element={<CreateJob />} />
                        <Route path="/:lang/admin/ads/create-job/:id" element={<CreateJob />} />
                        <Route path="/admin/ads/edit-job/:id/:jobId" element={<CreateJob />} />
                        <Route path="/:lang/admin/ads/edit-job/:id/:jobId" element={<CreateJob />} />
                        <Route path="/admin/candidate/:jobId/:id" element={<CandidateDetail />} />
                        <Route path="/:lang/admin/candidate/:jobId/:id" element={<CandidateDetail />} />
                        <Route path="/admin/ads/published-success" element={<PublishedSuccess />} />
                        <Route path="/:lang/admin/ads/published-success" element={<PublishedSuccess />} />
                        <Route path="*" element={<Navigate to={`/${lang ? lang : "en"}/admin/`} />} />
                    </Route>
                }
            </Routes>
        </>
    );
};

export default Hiring;