export const url = {
    baseUrl: "https://mmrc-dev-api.stagemyapp.com",
    // baseUrl: "https://mmrc-prod-api.stagemyapp.com",
    // baseUrl: "https://api.mmrcl.com/",
    // domains: {
    //     main: "localhost",
    //     recruitment: "recruitment.localhost",
    //     hiring: "hiring.localhost",
    //     vigilance: "vigilance.localhost",
    //     grievance: "grievance.localhost"
    // },
    domains: {
        main: "mmrc-dev.stagemyapp.com",
        recruitment: "recruitment.mmrc-dev.stagemyapp.com",
        hiring: "hiring.mmrc-dev.stagemyapp.com",
        vigilance: "vigilance.mmrc-dev.stagemyapp.com",
        grievance: "grievance.mmrc-dev.stagemyapp.com"
    },
    // domains: {
    //     main: "mmrcl.com",
    //     recruitment: "recruitment.mmrcl.com",
    //     hiring: "hiring.mmrcl.com",
    //     vigilance: "vigilance.mmrcl.com",
    //     grievance: "grievance.mmrcl.com"
    // },
    candidate_registeration: "/recruitment/candidate-registeration/",
    login: "/api/v2/login/",
    registeration: "/api/v2/registration/",
    verifyEmail: "/recruitment/verify-account/",
    sendOtp: "/recruitment/send-otp/",
    category: "/recruitment/categories/",
    nationality: "/recruitment/country-list/",
    state: "/recruitment/state-list/",
    qualificationList: "/recruitment/qualification-list/",
    candidate_detail: "/recruitment/api/candidate-details/",
    admin_login: "/api/v2/admin-login/",
    createAd: "/recruitment/api/advertisement/",
    publicAd: "/recruitment/api/advertisement-public-list/",
    publicJob: "/recruitment/api/job-public-list/",
    createJob: "/recruitment/api/job/",
    locationList: "/recruitment/locations/",
    deptList: "/recruitment/departments/",
    gradeList: "/recruitment/grade-pay-scales/",
    recruitmentFooter: "/recruitment/recruitment-footer-api/",
    jobCategory: "/recruitment/job-by-category/",
    relatedJob: "/recruitment/api/related-jobs/",
    applyJob: "/recruitment/api/apply-jobs/",
    candidateList: "/recruitment/api/job-applied-candidate-list/",
    empNature: "/recruitment/emp-nature-list/",
    applyStatus: "/recruitment/apply-status-tab-list/",
    hrStatistics: "/recruitment/statistics/",
    applyedInfo: "/recruitment/job-applied-instance/",
    deleteFile: "/recruitment/delete-pdf/",
    stationDetail: "/api/v2/station-detail/",
    searchStation: "/api/v2/station-by-keyword/",
    travel_distance_time_fare: "/api/v2/fare-calculator/",
    notice: "/api/v2/notification/passenger/",
    facility: "/api/v2/filter-category-list/",
    search_by_facility_station: "/api/v2/search-by-facility-category/",
    menu: "/api/v2/menu-list/",
    line_list: "/api/v2/line-list/",
    storeCategory: "/api/v2/store-categories/",
    store: "/api/v2/station-stores/",
    station_route: "/api/v2/journey-route/",
    searchStOrLt: "/api/v2/station-localities-by-keyword/",
    pages: "/api/v2/passenger/",
    lost_and_found: "/api/v2/lost-found-items-list/",
    item_category_list: "/api/v2/item-category-list/",
    report_lost_items: "/api/v2/report-lost-items/",
    applied_candidate: "/recruitment/job-applied-candidate-detail/",
    create_complaint: "/api/v2/create-complaint/",
    complaint_category: "/api/v2/complaint-category-list/",
    dept: "/api/v2/complaint-department-list/",
    complaintStatistics: "/api/v2/complaint-dashboard-statistics/",
    resolvedComplaint: "/api/v2/complaint-list/",
    send_otp: "/api/v2/send-otp/",
    forgot_password: "/api/v2/forgot-password/",
    status_wise_complaint: "/api/v2/status-wise-complaint-list/",
    complaint_details: "/api/v2/complaint-details/",
    assign_complaint: "/api/v2/assign-complaint/",
    reslove_compaint: "/api/v2/mark-complaint-resolved/",
    user_search: "/api/v2/user-department-list/",
    search_api: "/api/v2/search-passenger-page/",
    tours: "/api/v2/tourism/",
    download_form: "/recruitment/download-applied-form/",
    contact_us: "/api/v2/contact-us/",
    create_ticket: "/api/v2/vigilance/create-ticket/",
    grievance_visitors: "/api/v2/grievances-total-visitors/"
};