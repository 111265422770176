/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import { CheckEye, Eye, CloseBtn, AutoNew, Warn, Speaker } from "../svg/AllSvg";
import useAxios from "../../../shared/hooks/use-axios";
import { url } from "../../../shared/utils/url";
import { authActions } from "../../../shared/store/auth-Slice";
import { loadCaptchaEnginge, validateCaptcha, LoadCanvasTemplateNoReload } from 'react-simple-captcha';
import SecondaryBtn from "../form/SecondaryBtn";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';

const Login = ({ type }: any) => {
    const loginOpen: boolean = useSelector((state: any) => state.data.loginOpen);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const [hide, setHide] = useState<boolean>(true);
    const [submit, setSubmit] = useState<boolean>(false);
    const [passFcs, setPassFcs] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);
    const [captchaValue, setCaptchaValue] = useState<string>("")
    const [captchaError, setCaptchaError] = useState<string>("")
    const [captchaExpirationTime, setCaptchaExpirationTime] = useState(Date.now() + (5 * 60 * 1000));
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const params = useParams();
    const navigate = useNavigate();
    const ref: any = useRef();

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target) && type !== "admin") {
                dispatch(dataActions.setLoginOpen(false));
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27 && type !== "admin") {
            dispatch(dataActions.setLoginOpen(false))
        }
    };

    const handlePassword = () => {
        setHide(!hide);
    };

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: yup.object().shape({
            email: yup.string().email(t("auth.form.validation.wrong_email")).required(t("auth.form.validation.email")),
            password: yup.string().required(t("auth.form.validation.password")),
        }),
        onSubmit: async (values: any) => {
            //submit
            if (!!!captchaValue) {
                setCaptchaError(t("auth.form.validation.captcha"))
                return;
            }

            if (validateCaptcha(captchaValue) !== true) {
                setCaptchaError(t("auth.form.validation.match_captcha"));
                return;
            }

            try {
                setSubmit(true)
                let obj = {
                    username: values.email,
                    password: values.password,
                }

                let api: any = ""
                if (type === "admin") {
                    api = await useAxios.post(`/${(!!lang ? lang : "en") + url?.admin_login}`, obj);
                } else {
                    api = await useAxios.post(`/${(!!lang ? lang : "en") + url?.login}`, obj);
                }

                if (!!api) {
                    const res: any = api

                    if (res && res?.status === 200) {
                        if (type === "admin") {
                            dispatch(authActions.adminLogin());
                            let sessionTimeout: any = (new Date().getTime() + (86400000));
                            localStorage.setItem('adminSessionTimeout', sessionTimeout);
                            dispatch(authActions.adminDetail(res?.data?.data))
                            localStorage.setItem('admindetail', JSON.stringify(res?.data?.data));
                            localStorage.setItem('admintoken', res?.data?.data?.auth_token);
                            navigate(!!params?.lang ? `/${params?.lang}/admin` : "/admin")
                        } else {
                            dispatch(authActions.login());
                            let sessionTimeout: any = (new Date().getTime() + (86400000));
                            localStorage.setItem('userSessionTimeout', sessionTimeout);
                            dispatch(authActions.userDetail(res?.data?.data))
                            localStorage.setItem('userdetail', JSON.stringify(res?.data?.data));
                            localStorage.setItem('usertoken', res?.data?.data?.auth_token);
                            // session
                            Cookies.set('userSessionTimeout', sessionTimeout, {
                                domain: url?.domains?.main,
                                path: '/',
                                secure: true,
                                sameSite: 'None'
                            });

                            Cookies.set('userSessionTimeout', sessionTimeout, {
                                domain: url?.domains?.recruitment,
                                path: '/',
                                secure: true,
                                sameSite: 'None'
                            });

                            Cookies.set('userSessionTimeout', sessionTimeout, {
                                domain: url?.domains?.hiring,
                                path: '/',
                                secure: true,
                                sameSite: 'None'
                            });

                            Cookies.set('userSessionTimeout', sessionTimeout, {
                                domain: url?.domains?.vigilance,
                                path: '/',
                                secure: true,
                                sameSite: 'None'
                            });

                            Cookies.set('userSessionTimeout', sessionTimeout, {
                                domain: url?.domains?.grievance,
                                path: '/',
                                secure: true,
                                sameSite: 'None'
                            });

                            // user-details
                            Cookies.set('userdetail', JSON.stringify(res?.data?.data), {
                                domain: url?.domains?.main,
                                path: '/',
                                secure: true,
                                sameSite: 'None'
                            });

                            Cookies.set('userdetail', JSON.stringify(res?.data?.data), {
                                domain: url?.domains?.recruitment,
                                path: '/',
                                secure: true,
                                sameSite: 'None'
                            });

                            Cookies.set('userdetail', JSON.stringify(res?.data?.data), {
                                domain: url?.domains?.hiring,
                                path: '/',
                                secure: true,
                                sameSite: 'None'
                            });

                            Cookies.set('userdetail', JSON.stringify(res?.data?.data), {
                                domain: url?.domains?.vigilance,
                                path: '/',
                                secure: true,
                                sameSite: 'None'
                            });

                            Cookies.set('userdetail', JSON.stringify(res?.data?.data), {
                                domain: url?.domains?.grievance,
                                path: '/',
                                secure: true,
                                sameSite: 'None'
                            });

                            // token
                            Cookies.set('usertoken', res?.data?.data?.auth_token, {
                                domain: url?.domains?.main,
                                path: '/',
                                secure: true,
                                sameSite: 'None'
                            });

                            Cookies.set('usertoken', res?.data?.data?.auth_token, {
                                domain: url?.domains?.recruitment,
                                path: '/',
                                secure: true,
                                sameSite: 'None'
                            });

                            Cookies.set('usertoken', res?.data?.data?.auth_token, {
                                domain: url?.domains?.hiring,
                                path: '/',
                                secure: true,
                                sameSite: 'None'
                            });

                            Cookies.set('usertoken', res?.data?.data?.auth_token, {
                                domain: url?.domains?.vigilance,
                                path: '/',
                                secure: true,
                                sameSite: 'None'
                            });

                            Cookies.set('usertoken', res?.data?.data?.auth_token, {
                                domain: url?.domains?.grievance,
                                path: '/',
                                secure: true,
                                sameSite: 'None'
                            });
                        }


                        dispatch(dataActions.setLoginOpen(false))
                        setSubmit(false)
                    }
                }
            } catch (error: any) {
                if (error && error?.response?.status === 400) {
                    setError({ ...error, email: error?.response?.data?.errors?.toString(), password: error?.response?.data?.errors?.toString() })
                }
                setSubmit(false)
                console.error("error while login", error);
            }
        }
    });

    useEffect(() => {
        setTimeout(() => {
            loadCaptchaEnginge(4, "#FFF");
            setCaptchaValue("")
        }, 200)
    }, [])

    const loadNewCaptcha = () => {
        loadCaptchaEnginge(4, "#FFF");
        setCaptchaValue("")
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            loadCaptchaEnginge(4, "#FFF");
            setCaptchaValue("")
            setCaptchaExpirationTime(Date.now() + (5 * 60 * 1000));
        }, captchaExpirationTime - Date.now());

        return () => clearTimeout(timer);
    }, [captchaExpirationTime]);

    const handleForgotPassword = () => {
        if (type === "admin") {
            dispatch(dataActions.setResetPasswordOpen(true));
            dispatch(dataActions.setLoginOpen(false));
            !!params?.lang ? navigate(`/${params?.lang}/admin/reset-password`) : navigate(`/admin/reset-password`)
        } else {
            dispatch(dataActions.setResetPasswordOpen(true));
            dispatch(dataActions.setLoginOpen(false));
        }
    }

    return (
        <>
            {loginOpen &&
                <div className={`fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto ${type === "admin" ? "bg-white" : "bg-[rgba(171,171,171,0.70)] backdrop-blur-[10px]"}`}>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className={`relative m-[15px] mm:mx-auto bg-white rounded-[15px] w-full xsm:w-[480px] h-full lg:min-h-[600px] ${type === "admin" ? "shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)]" : "shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12)"}`} ref={ref}>
                            <form className="flex flex-col relative w-full h-full items-start justify-center p-[20px] xlx:p-[24px] z-[1]">
                                <div className="flex w-full justify-between">
                                    <div className="flex flex-col w-full xlx:gap-[4px]">
                                        <h3 className="font-bold text-primaryText">{type === "admin" ? t("auth.HRLoginTitle") : t("auth.loginTitle")}</h3>
                                        <h6 className="text-blackDark font-medium">{t("auth.loginDesc")}</h6>
                                    </div>
                                    {type !== "admin" && <button className="w-[23px] h-[23px] rounded-full" type="button" onClick={() => dispatch(dataActions.setLoginOpen(false))}>
                                        <CloseBtn />
                                    </button>}
                                </div>

                                <div className="flex flex-col gap-[18px] pt-[20px] lg:pt-[33px] w-full">
                                    <div className="flex flex-col gap-[4px]">
                                        <h6 className="text-blackDark font-medium">{t("auth.form.field.email")}</h6>
                                        <input className="rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] h-[35px] xlx:h-[40px] px-[15px] xlx:px-[17px] text-blackDull placeholder:text-blackDull text-sm leading-snug" type="email" name="email" placeholder={t("auth.form.field.email")} value={formik?.values?.email} onBlur={formik?.handleBlur} onChange={formik?.handleChange} autoComplete="off" />
                                        {formik?.touched?.email && formik?.errors?.email && <div className="flex gap-[6px] items-start"><Warn />
                                            <span className="text-danger text-xs font-medium">{formik?.errors?.email}</span>
                                        </div>}
                                        {(!!error?.email && !!!formik?.errors?.email) && <div className="text-danger text-xs flex gap-[6px]"><Warn /> {t("auth.form.validation.incorrect_email")}</div>}
                                    </div>

                                    <div className="flex flex-col gap-[4px]">
                                        <h6 className="text-blackDark font-medium">{t("auth.form.field.password")}</h6>
                                        <div className={`rounded-[6px] border border-[#D7D8DD] h-[35px] xlx:h-[40px] w-full relative flex items-center ${passFcs ? "bg-white" : "hover:bg-white bg-[#F5F5F5]"}`}>
                                            <input className="rounded-[6px] bg-transparent w-full text-blackDull text-sm leading-snug h-full pl-[15px] xlx:pl-[17px] pr-[40px] placeholder:text-blackDull" type={hide ? "password" : "text"} name="password" placeholder={t("auth.form.field.password")} value={formik?.values?.password} onBlur={(e: any) => { formik?.handleBlur(e); setPassFcs(false) }} onChange={formik?.handleChange} autoComplete="off" onFocus={() => setPassFcs(true)} />

                                            <button type="button" className="absolute right-[10px] cursor-pointer h-full" onClick={handlePassword}>
                                                {hide ? <CheckEye /> : <Eye />}
                                            </button>
                                        </div>
                                        {formik?.touched?.password && formik?.errors?.password && <div className="flex gap-[6px] items-start"><Warn />
                                            <span className="text-danger text-xs font-medium">{formik?.errors?.password}</span>
                                        </div>}
                                        {(!!error?.password && !!!formik?.errors?.password) && <div className="text-danger text-xs flex gap-[6px]"><Warn /> {t("auth.form.validation.incorrect_password")}</div>}
                                    </div>

                                    {type !== "admin" && <div className="flex items-end justify-end w-full pt-[15px] mt-[-18px]">
                                        <button className="text-blackDull text-sm leading-snug font-normal hover:underline focus:underline" onClick={handleForgotPassword} type="button">{t("auth.forgot_password")}</button>
                                    </div>}

                                    <div className={`flex flex-col ${type === "admin" && "pt-[20px]"}`}>
                                        <div className="gap-[10px] flex">
                                            <div>
                                                <div className="flex flex-col gap-[8px]">
                                                    <div className="bg-[#FFF] rounded-[5px] w-fit h-[35px] xlx:h-[40px] pr-[10px] flex items-center justify-center border border-[#D7D8DD]">
                                                        <LoadCanvasTemplateNoReload />
                                                    </div>
                                                    <div className="flex items-center gap-[10px]">
                                                        <button type="button" className="min-w-[12px]" onClick={loadNewCaptcha}>
                                                            <AutoNew />
                                                        </button>
                                                        <button type="button" className="min-w-[13px]">
                                                            <Speaker />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col gap-[4px]">
                                                <input
                                                    type="text"
                                                    id="captchaInput"
                                                    name="captchaInput"
                                                    className="rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] h-[35px] xlx:h-[40px] px-[15px] xlx:px-[17px] text-blackDull text-sm leading-snug max-w-[131px]"
                                                    value={captchaValue}
                                                    onChange={(e: any) => { setCaptchaValue(e.target.value); setCaptchaError(""); }}
                                                    placeholder={t("auth.form.field.captcha")}
                                                    autoComplete="off"
                                                />
                                                {!!captchaError && <div className="flex gap-[6px] items-start"><Warn /><span className="text-danger text-xs font-medium">{captchaError}</span></div>}

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <SecondaryBtn title={t("auth.loginBtn")} css="w-full flex items-center justify-center uppercase !rounded-[10px] mt-[30px] font-bold" onClick={formik?.handleSubmit} disabled={submit} />

                                <div className="flex items-center justify-center w-full pt-[15px] mb-[114px] xlx:mb-[110px]">
                                    {type !== "admin" && <div className="text-[#606060] text-sm leading-snug font-normal">{t("auth.no_account")} &nbsp;<button className="text-primary text-sm leading-snug font-semibold focus:underline hover:underline" type="button" onClick={() => { dispatch(dataActions.setRegisterOpen(true)); dispatch(dataActions.setLoginOpen(false)); }}>{t("auth.register")}</button></div>}
                                </div>
                            </form>

                            <div className="absolute top-0 left-0 right-0 bottom-0 flex items-end justify-end">
                                <img src={require("../../../assets/images/login-bg.png")} alt="login-bg" className="rounded-b-[15px] w-full" />
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default Login;