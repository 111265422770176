/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import VigilanceLayout from "./VigilanceLayout";
import { Navigate, Route, Routes } from "react-router-dom";
import ImpNotice from "../../../components/corporate/vigilance/Home/ImpNotice";
const Home = React.lazy(() => import("../../../pages/corporate/vigilance/home/Home"));
const LandingPage = React.lazy(() => import("../../../pages/corporate/vigilance/landingPage/LandingPage"));
const Login = React.lazy(() => import("../../../pages/corporate/vigilance/auth/Login"));
const Register = React.lazy(() => import("../../../pages/corporate/vigilance/auth/Register"));

const Vigilance = () => {
    const isLogin: boolean = useSelector((state: any) => state.auth.isLogin);
    const impNotice: any = useSelector((state: any) => state.data.impNotice);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const dispatch = useDispatch();

    useEffect(() => {
        let host: string[] = window.location.hostname.split(".");
        if (host?.length > 0) {
            dispatch(dataActions.setTenant(host?.includes("www") ? host[1] : host[0]))
        }
    }, [window.location.hostname])

    useEffect(() => {
        if (impNotice) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [impNotice]);

    return (
        <>
            {impNotice && <ImpNotice />}
            <Routes>
                {!isLogin ? <>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/:lang" element={<LandingPage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/:lang/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/:lang/register" element={<Register />} />
                    <Route path="*" element={<Navigate to={`/${lang ? lang : "en"}`} />} />
                </> :
                    <Route element={<VigilanceLayout />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/:lang" element={<Home />} />
                        <Route path="/register-new-complaint" element={<Home popup="register-new-complaint" />} />
                        <Route path="/:lang/register-new-complaint" element={<Home popup="register-new-complaint" />} />
                        <Route path="*" element={<Navigate to={`/${lang ? lang : "en"}`} />} />
                    </Route>
                }
            </Routes>
        </>
    );
};

export default Vigilance;