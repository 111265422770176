import Select from "react-select";
import useSelect from "../../../shared/hooks/use-select";

const SelectComponent = ({ options, placeholder, name, title, components, menuIsOpen, onMenuOpen, onMenuClose, onInputChange, inputValue, onChange, value, onFocus, onBlur, isSearchable, arrow, popup }: any) => {
    let styles = useSelect(arrow);

    const filterOptions = (
        candidate: { label: string; value: string; data: any },
        input: string
    ) => {
        return true;
    };
	
	const handleKeyDown = (e: any) => {
        if (e?.key === 'Backspace' && e?.target?.value?.length <= 0) {
            e.preventDefault(); // Prevent the default behavior of the "Backspace" key
        }
    };

    return (
        <div className={`w-full rounded-[10px] border border-[rgba(134,134,134,0.25)] bg-[#F8F8F8] flex flex-col  ${popup ? "px-[12px] sm:px-[17px] xlx:px-[22px] xlx:pt-[9px] xlx:pb-[4px] pt-[8px] pb-[2px]" : "pt-[8px] px-[12px] sm:px-[17px]"}`}>
            <h6 className="text-blackDark font-medium">{title}</h6>
            <Select
                options={options}
                name={name}
                className="w-full mt-[-3px] xlx:mt-[-1px] select-input"
                placeholder={placeholder}
                styles={styles}
                components={components}
                isSearchable={isSearchable}
                menuIsOpen={menuIsOpen}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                onInputChange={onInputChange}
                inputValue={inputValue}
                onChange={onChange}
                value={value}
                noOptionsMessage={() => null}
                onFocus={onFocus}
                onBlur={onBlur}
                isClearable
                filterOption={filterOptions}
				onKeyDown={handleKeyDown}
				aria-label={title || placeholder}
                 classNamePrefix="react-select-station"
                 maxMenuHeight={390}
            />
        </div>
    );
};

export default SelectComponent;
