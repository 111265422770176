/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import { CloseBtn, LeftArr, Warn } from "../svg/AllSvg";
import useAxios from "../../../shared/hooks/use-axios";
import { url } from "../../../shared/utils/url";
import SecondaryBtn from "../form/SecondaryBtn";
import { useNavigate, useParams } from "react-router-dom";

const ResetPassword = ({ type }: any) => {
  const resetPasswordOpen: boolean = useSelector((state: any) => state.data.resetPasswordOpen);
  const lang: string = useSelector((state: any) => state.ui.lang);
  const [submit, setSubmit] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const ref: any = useRef();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target) && type !== "admin") {
        dispatch(dataActions.setResetPasswordOpen(false));
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  document.onkeydown = function (evt: any) {
    if (evt.keyCode === 27 && type !== "admin") {
      dispatch(dataActions.setResetPasswordOpen(false))
    }
  };

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: ""
    },
    validationSchema: yup.object().shape({
      email: yup.string().email(t("auth.form.validation.wrong_email")).required(t("auth.form.validation.email"))
    }),
    onSubmit: async (values: any) => {
      try {
        setSubmit(true)
        let obj = {
          email: values.email,
          type: "forgot_password",
        }
        const res: any = await useAxios.post(`/${(!!lang ? lang : "en") + url?.send_otp}`, obj);

        if (res && res?.status === 200) {
          localStorage.setItem("email", values?.email)
          localStorage.setItem("auth_type", "forgot_password")
          dispatch(dataActions.setResetPasswordOpen(false))
          dispatch(dataActions.setVerifyEmailOpen(true))
          setSubmit(false)
        }
      } catch (error: any) {
        if (error && error?.response?.status === 400) {
          setError({ ...error, email: error?.response?.data?.message })
        }
        setSubmit(false)
        console.error("error while reset password", error);
      }
    }
  });

  const handleBack = () => {
    if (type === "admin") {
      dispatch(dataActions.setLoginOpen(true));
      dispatch(dataActions.setResetPasswordOpen(false));
      !!params?.lang ? navigate(`/${params?.lang}/admin/login`) : navigate(`/admin/login`)
    } else {
      dispatch(dataActions.setLoginOpen(true));
      dispatch(dataActions.setResetPasswordOpen(false));
    }
  }

  return (
    <>
      {resetPasswordOpen &&
        <div className={`fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto ${type === "admin" ? "bg-white" : "bg-[rgba(171,171,171,0.70)] backdrop-blur-[10px]"}`}>
          <div className="flex justify-center items-center min-h-screen">
            <div className={`relative m-[15px] mm:mx-auto bg-white rounded-[15px] w-full xsm:w-[480px] h-full lg:min-h-[457px] ${type === "admin" ? "shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)]" : "shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12)"}`} ref={ref}>
              <div className="flex flex-col relative w-full h-full items-start justify-center p-[20px] xlx:p-[24px] z-[1]">
                <div className="flex w-full justify-between">
                  <div className="flex flex-col w-full xlx:gap-[4px]">
                    <div className="flex items-center gap-[4px] ml-[-10px]">
                      <button className=" flex items-center justify-center w-[25px] h-[25px] rounded-full border border-white hover:border hover:border-[#ddd] focus:border focus:border-[#ddd] bg-white" onClick={handleBack}>
                        <LeftArr />
                      </button>
                      <h3 className="font-bold text-primaryText">{t("auth.reset_password")}
                      </h3>
                    </div>
                    <h6 className="text-blackDark font-medium">{t("auth.reset_password_desc")}</h6>
                  </div>
                  {type !== "admin" && <button className="w-[23px] h-[23px] rounded-full" type="button" onClick={() => dispatch(dataActions.setResetPasswordOpen(false))}>
                    <CloseBtn />
                  </button>}
                </div>

                <div className="flex flex-col gap-[18px] pt-[20px] lg:pt-[33px] w-full">
                  <div className="flex flex-col gap-[4px]">
                    <h6 className="text-blackDark font-medium">{t("auth.form.field.email")}</h6>
                    <input className="placeholder:text-blackDull rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] h-[35px] xlx:h-[40px] px-[15px] xlx:px-[17px] text-blackDull text-sm leading-snug" type="email" name="email" placeholder={t("auth.form.field.email")} value={formik?.values?.email} onBlur={formik?.handleBlur} onChange={formik?.handleChange} autoComplete="off" />
                    {formik?.touched?.email && formik?.errors?.email && <div className="flex gap-[6px] items-start"><Warn />
                      <span className="text-danger text-xs font-medium">{formik?.errors?.email}</span>
                    </div>}
                    {(!!error?.email && (!!!formik?.errors?.email && formik?.touched?.email)) && <div className="text-danger text-xs flex gap-[6px]"><Warn /> {t("auth.form.validation.incorrect_email")}</div>}
                  </div>
                </div>

                <SecondaryBtn title="Send Link ON Mail" css="w-full flex items-center justify-center uppercase !rounded-[10px] mt-[30px] font-bold" disabled={submit} onClick={formik?.handleSubmit} />

                <div className="flex items-center justify-center w-full pt-[15px] mb-[159px] xlx:mb-[155px]">
                  {type !== "admin" && <div className="text-[#606060] text-sm leading-snug font-normal">{t("auth.no_account")} &nbsp;<button className="text-primary text-sm leading-snug font-semibold focus:underline hover:underline" type="button" onClick={() => { dispatch(dataActions.setRegisterOpen(true)); dispatch(dataActions.setResetPasswordOpen(false)); }}>{t("auth.register")}</button></div>}
                </div>
              </div>

              <div className="absolute top-0 left-0 right-0 bottom-0 flex items-end justify-end">
                <img src={require("../../../assets/images/reset-password-bg.png")} alt="reset-password-bg" className="rounded-b-[15px] w-full" />
              </div>
            </div>
          </div>
        </div>}
    </>
  );
};

export default ResetPassword;