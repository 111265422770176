import { useDispatch, useSelector } from 'react-redux';
import { dataActions } from '../../../shared/store/data-Slice';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CloseBtn, TableSearch } from '../svg/AllSvg';
import { useTranslation } from 'react-i18next';

const SearchPopup = () => {
    const searchOpen: boolean = useSelector((state: any) => state.data.searchOpen);
    const [search, setSearch] = useState<string>("");
    const [focus, setFocus] = useState<boolean>(false);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setSearchOpen(false))
        }
    };

    useEffect(() => {
        !!params?.search && setSearch(params?.search);
    }, [params?.search])


    return (
        <>
            {searchOpen &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]" onClick={() => dispatch(dataActions.setSearchOpen(false))}
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] md:mx-auto bg-white rounded-[10px] h-auto md:w-[680px] xlx:w-[725px] relative px-[15px] xsm:px-[30px] xlx:px-[40px] pb-[25px] pt-[35px] xsm:pt-[55px] xlx:pt-[60px] shadow-[0px_4px_6px_-4px_rgba(24,39,75,0.12),0px_8px_8px_-4px_rgba(24,39,75,0.08)] flex flex-col gap-[40px] xlx:gap-[50px]">
                            <button className="absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px]" onClick={() => dispatch(dataActions.setSearchOpen(false))}>
                                <CloseBtn className="min-w-[23px]" />
                            </button>

                            <div className='flex flex-col gap-[10px] xlx:gap-[15px] w-full items-center'>
                                <h2 className='text-primary font-bold text-center'>{t("passenger.search.title")} <span className='text-secGreen'>{t("passenger.home.footer.mmrcl")}</span></h2>

                                <form onSubmit={(e: any) => { e.preventDefault(); !!params?.lang ? navigate(`/${params?.lang}/search/${search}`) : navigate(`/search/${search}`); dispatch(dataActions.setSearchOpen(false)) }} className={`border border-[#D7D8DD] h-[38px] xsm:h-[40px] px-[15px] xlx:px-[17px] flex items-center gap-[8px] xlx:gap-[12px] rounded-full w-full xsm:w-[400px] xlx:w-[445px] ${focus ? "bg-white" : "bg-[#F5F5F5] hover:bg-white"}`}>
                                    <TableSearch className="xlx:w-[18px] xlx:min-w-[18px] xlx:h-[18px] min-w-[15px] w-[15px] h-[15px] fill-primary" />

                                    <input type="text" className="text-opacity-[0.7] text-blackDull font-medium placeholder:text-blackDull placeholder:text-opacity-[0.7] text-sm leading-tight placeholder:leading-tight w-full bg-transparent" placeholder={t("grievance.home.banner.search")} autoComplete="off" value={search} onChange={(e: any) => setSearch(e.target.value)} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} autoFocus />
                                </form>

                                <p className='text-opacity-[0.7] font-normal text-blackDull text-center'>{t("passenger.search.desc")}</p>
                            </div>

                            <img src={require("../../../assets/images/search-bg.png")} alt="search-bg" />
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default SearchPopup;