export const useSelectSecondary = (arrow: any, err: any): any => {
  let styles: any = {
    menu: (provided: any, state: any) => ({
      ...provided,
      zIndex: "20",
      borderRadius: "10px",
      background: "#fff",
      boxShadow: "0px 10px 32px -4px rgba(24, 39, 75, 0.10), 0px 6px 14px -6px rgba(24, 39, 75, 0.12)"
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontSize: "0.875rem",
      fontWeight: "400",
      color: state.isSelected ? "#059DB2" : state.isFocused ? "#5A5A5A" : "#5A5A5A",
      backgroundColor: state.isSelected ? "#EDEDED" : state.isFocused ? "#EDEDED" : "#fff",
      cursor: "pointer",
      border: "none",
      "&:hover": {
        backgroundColor: state.isDisabled ? "normal" : "#EDEDED",
        border: "none",
        color: state.isDisabled ? "#686868" : "#686868",
      },
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "10px"
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: (err ? "#F00" : "#4E566D"),
      fontSize: "0.875rem",
      fontWeight: "400"
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      padding: "0px 10px"
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: state.isFocused ? "#4E566D" : "#4E566D",
      fontSize: "0.875rem",
      fontWeight: "400",
      "&:hover": {
        color: state.isFocused ? "#4E566D" : "#4E566D",
      },
      padding: 0,
      paddingRight: "10px",
      display: !arrow ? "none" : "block",
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      display: "none",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: state.isFocused ? (err ? "1px solid #F00" : "1px solid #D7D8DD") : (err ? "1px solid #F00" : "1px solid #D7D8DD"),
      backgroundColor: state.isFocused ? "#fff" : (err ? "#FFEAEA" : "#F5F5F5"),
      borderRadius: state.isFocused ? "6px" : "6px",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        backgroundColor: "#fff",
      },
      cursor: !arrow ? "text" : "pointer",
      minHeight: '40px',
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      color: (err ? "#F00" : "#4E566D"),
      fontSize: "0.875rem",
      fontWeight: "400"
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: (err ? "#F00" : "#4E566D"),
      fontSize: "0.875rem",
      fontWeight: "400",
    }),
  };

  return styles;
};

export default useSelectSecondary;