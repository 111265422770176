/* eslint-disable no-useless-concat */
import axios from "axios";
import { url } from "../utils/url";
import Cookies from 'js-cookie';

const useAxios = axios.create({
    baseURL: url?.baseUrl
});

const requestHandler = (req: any): any => {
    // if (window?.location?.pathname.includes("/admin")) {
    //     if (localStorage.getItem('admintoken')) {
    //         req.headers['Authorization'] = 'Token' + ' ' + localStorage.getItem('admintoken');
    //     }
    // } else {
    //     if (localStorage.getItem('usertoken')) {
    //         req.headers['Authorization'] = 'Token' + ' ' + localStorage.getItem('usertoken');
    //     }
    // }
    if (window?.location?.pathname.includes("/admin")) {
        if (localStorage.getItem('admintoken')) {
            req.headers['Authorization'] = 'Token' + ' ' + localStorage.getItem('admintoken');
        }
    } else {
        if (Cookies.get('usertoken')) {
            req.headers['Authorization'] = 'Token' + ' ' + Cookies.get('usertoken');
        }
    }
    return req;
};

const responseHandler = (res: any): any => {
    return res;
};

const errorHandler = (error: any): any => {
    if (error && error?.response?.status === 401) {
        if (window?.location?.pathname.includes("/admin")) {
            localStorage.removeItem("adminSessionTimeout");
            localStorage.removeItem("admindetail");
            localStorage.removeItem("admintoken");
            window.location.href = '/admin/login';
        } else {
            localStorage.removeItem("userSessionTimeout");
            localStorage.removeItem("userdetail");
            localStorage.removeItem("usertoken");
        }
    }

    return Promise.reject(error);
};

useAxios.interceptors.request.use(
    (req: any) => requestHandler(req),
    (error: any) => errorHandler(error)
);

useAxios.interceptors.response.use(
    (res: any) => responseHandler(res),
    (error: any) => errorHandler(error)
);

export default useAxios;