/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter as Router } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import Main from "./routes/main/Main";
import Loader from "./components/common/layout/Loader";
import { useSelector } from "react-redux";
import { getApp } from "./shared/utils/helpers";
import Recruitment from "./routes/corporate/recruitment/Recruitment";
import Hiring from "./routes/corporate/hiring/Hiring";
import Vigilance from "./routes/corporate/vigilance/Vigilance";
import OtherWebLink from "./components/common/layout/OtherWebLink";
import Grievance from "./routes/corporate/grievance/Grievance";

const MainRouter = () => {
  const theme: any = useSelector((state: any) => state.ui.theme);
  const webOpen: boolean = useSelector((state: any) => state.data.webOpen);
  let [loader, setLoader] = useState<any>(true);
  const [tenantData, setTenantData] = useState<any[]>([]);
  const Components: any = !loader && getApp(tenantData);

  useEffect(() => {
    if (webOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [webOpen]);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }

    !!theme && localStorage.setItem("theme", theme);
  }, [theme]);

  const getTenant = () => {
    // for tenant setting
    // let tenantList: any[] = [{ name: "recruitment", component: Recruitment }, { name: "recruitment", component: Hiring }];
    let tenantList: any[] = [{ name: "recruitment", component: window?.location?.pathname.includes("/admin") ? Hiring : Recruitment }, { name: "vigilance", component: Vigilance }, { name: "grievance", component: Grievance }];
    let arr: any[] = [];
    let tenant: any = "";
    for (let i = 0; i < tenantList?.length; i++) {
      const el = tenantList?.[i];

      if (window.location.hostname?.includes(el?.name)) {
        tenant = window.location.hostname?.replace(`${el?.name}.`, "")
      }

      let obj: any;
      if (!!tenant) {
        obj = {
          subdomain: el?.name + "." + tenant,
          app: el?.component,
          main: false
        }
      } else {
        obj = {
          subdomain: el?.name + "." + window.location.hostname,
          app: el?.component,
          main: false
        }
      }

      arr.push(obj)
    }
    arr.push({
      subdomain: !!tenant ? tenant : window.location.hostname,
      app: Main,
      main: true
    })

    setTenantData(arr);
    setLoader(false);
  }

  useEffect(() => {
    getTenant()
  }, [])

  return (
    <Suspense fallback={<Loader />}>
      {!loader &&
        <Router>
          {webOpen && <OtherWebLink />}
          <Components />
        </Router>
      }
    </Suspense>
  );
};

export default MainRouter;