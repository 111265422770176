import { createSlice } from '@reduxjs/toolkit';

interface DataState {
    routeData: any,
    routeApi: boolean,
    tenant: string,
    impNotice: boolean,
    loginOpen: boolean,
    registerOpen: boolean,
    resetPasswordOpen: boolean,
    profile: boolean,
    createAd: boolean,
    editAd: any,
    adApiCall: boolean,
    verifyEmailOpen: boolean,
    applySuccess: boolean,
    applyError: boolean,
    applyData: any,
    applyCondition: any,
    fareOpen: boolean,
    firstlastTime: boolean,
    stationDetailData: any,
    link: any,
    webOpen: boolean
    langOpen: boolean,
    selectLang: any,
    page: string,
    reportItemOpen: boolean,
    createComplaint: boolean,
    complaintDetail: boolean,
    resolveComplaint: boolean,
    newPasswordOpen: boolean,
    complaintApiCall: boolean,
    assignComplaint: boolean,
    searchOpen: boolean,
    tourOpen: boolean,
    tourDetail: any
}

const initialState: DataState = {
    routeData: null,
    routeApi: false,
    tenant: "",
    impNotice: true,
    loginOpen: false,
    registerOpen: false,
    resetPasswordOpen: false,
    profile: false,
    createAd: false,
    editAd: null,
    adApiCall: false,
    verifyEmailOpen: false,
    applySuccess: false,
    applyError: false,
    applyData: null,
    applyCondition: false,
    fareOpen: false,
    firstlastTime: false,
    stationDetailData: null,
    link: null,
    webOpen: false,
    langOpen: false,
    selectLang: null,
    page: "",
    reportItemOpen: false,
    createComplaint: false,
    complaintDetail: false,
    resolveComplaint: false,
    newPasswordOpen: false,
    complaintApiCall: false,
    assignComplaint: false,
    searchOpen: false,
    tourOpen: false,
    tourDetail: null
}

const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setRouteData: (state, action) => {
            state.routeData = action.payload;
        },
        setRouteApi: (state, action) => {
            state.routeApi = action.payload;
        },
        setTenant: (state, action) => {
            state.tenant = action.payload;
        },
        setImpNotice: (state, action) => {
            state.impNotice = action.payload;
        },
        setLoginOpen: (state, action) => {
            state.loginOpen = action.payload;
        },
        setRegisterOpen: (state, action) => {
            state.registerOpen = action.payload;
        },
        setResetPasswordOpen: (state, action) => {
            state.resetPasswordOpen = action.payload;
        },
        setProfile: (state, action) => {
            state.profile = action.payload;
        },
        setCreateAd: (state, action) => {
            state.createAd = action.payload;
        },
        setEditAd: (state, action) => {
            state.editAd = action.payload;
        },
        setAdApiCall: (state, action) => {
            state.adApiCall = action.payload;
        },
        setVerifyEmailOpen: (state, action) => {
            state.verifyEmailOpen = action.payload;
        },
        setApplySuccess: (state, action) => {
            state.applySuccess = action.payload;
        },
        setApplyError: (state, action) => {
            state.applyError = action.payload;
        },
        setApplyData: (state, action) => {
            state.applyData = action.payload;
        },
        setApplyCondition: (state, action) => {
            state.applyCondition = action.payload;
        },
        setFareOpen: (state, action) => {
            state.fareOpen = action.payload;
        },
        setFirstlastTimeOpen: (state, action) => {
            state.firstlastTime = action.payload;
        },
        setStationDetailData: (state, action) => {
            state.stationDetailData = action.payload;
        },
        setLink: (state, action) => {
            state.link = action.payload;
        },
        setWebOpen: (state, action) => {
            state.webOpen = action.payload;
        },
        setLangOpen: (state, action) => {
            state.langOpen = action.payload;
        },
        setSelectLang: (state, action) => {
            state.selectLang = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setReportItemOpen: (state, action) => {
            state.reportItemOpen = action.payload;
        },
        setCreateComplaint: (state, action) => {
            state.createComplaint = action.payload;
        },
        setComplaintDetail: (state, action) => {
            state.complaintDetail = action.payload;
        },
        setResolveComplaint: (state, action) => {
            state.resolveComplaint = action.payload;
        },
        setNewPasswordOpen: (state, action) => {
            state.newPasswordOpen = action.payload;
        },
        setComplaintApiCall: (state, action) => {
            state.complaintApiCall = action.payload;
        },
        setAssignComplaint: (state, action) => {
            state.assignComplaint = action.payload;
        },
        setSearchOpen: (state, action) => {
            state.searchOpen = action.payload;
        },
        setTourOpen: (state, action) => {
            state.tourOpen = action.payload;
        },
        setTourDetail: (state, action) => {
            state.tourDetail = action.payload;
        },
    }
});

export default dataSlice;
export const dataActions = dataSlice.actions;