/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { CheckEye, CloseBtn, Eye, Warn } from "../svg/AllSvg";
import SecondaryBtn from "../form/SecondaryBtn";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { url } from "../../../shared/utils/url";
import useAxios from "../../../shared/hooks/use-axios";

const NewPassword = ({ type }: any) => {
  const newPasswordOpen: boolean = useSelector((state: any) => state.data.newPasswordOpen);
  const lang: string = useSelector((state: any) => state.ui.lang);
  const [hide, setHide] = useState<boolean>(true);
  const [hide1, setHide1] = useState<boolean>(true);
  const [submit, setSubmit] = useState<boolean>(false);
  const [passFcs, setPassFcs] = useState<boolean>(false);
  const [cnfPassFcs, setCnfPassFcs] = useState<boolean>(false);
  const { t } = useTranslation('common');
  const ref: any = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target) && type !== "admin") {
        dispatch(dataActions.setNewPasswordOpen(false));
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  document.onkeydown = function (evt: any) {
    if (evt.keyCode === 27 && type !== "admin") {
      dispatch(dataActions.setNewPasswordOpen(false))
    }
  };

  const handlePassword = () => {
    setHide(!hide);
  };

  const handlePassword1 = () => {
    setHide1(!hide1);
  };

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      password2: ""
    },
    validationSchema: yup.object().shape({
      password: yup.string().matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/, t("auth.form.validation.wrong_password")).required(t("auth.form.validation.password")),
      password2: yup.string().oneOf([yup.ref("password"), ""], t("auth.form.validation.match_password")).required(t("auth.form.validation.confirm_password"))
    }),
    onSubmit: async (values: any) => {
      //submit
      try {
        setSubmit(true)
        let obj = {
          new_password1: values.password,
          new_password2: values.password2,
          email: localStorage.getItem("email")
        }
        const res: any = await useAxios.post(`/${(!!lang ? lang : "en") + url?.forgot_password}`, obj);

        if (res && res?.status === 200) {
          localStorage.removeItem("email")
          dispatch(dataActions.setNewPasswordOpen(false))
          dispatch(dataActions.setLoginOpen(true))
          setSubmit(false)
        }
      } catch (error: any) {
        setSubmit(false)
        console.error("error while create new password", error);
      }
    }
  });

  return (
    <>
      {newPasswordOpen &&
        <div className={`fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto ${type === "admin" ? "bg-white" : "bg-[rgba(171,171,171,0.70)] backdrop-blur-[10px]"}`}>
          <div className="flex justify-center items-center min-h-screen">

            <div className={`relative m-[15px] mm:mx-auto bg-white rounded-[15px] w-full xsm:w-[480px] h-full lg:min-h-[510px] ${type === "admin" ? "shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)]" : "shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12)"}`}>
              <form className="flex flex-col relative w-full h-full items-start justify-center p-[20px] xlx:p-[24px] z-[1]">
                <div className="flex w-full justify-between">
                  <div className="flex flex-col w-full xlx:gap-[4px]">
                    <h3 className="font-bold text-primaryText">{t("auth.new_password")}</h3>
                    <h6 className="text-blackDark font-medium">{t("auth.new_password_desc")}</h6>
                  </div>

                  {type !== "admin" && <button className="w-[23px] h-[23px] rounded-full" type="button" onClick={() => dispatch(dataActions.setNewPasswordOpen(false))}>
                    <CloseBtn />
                  </button>}
                </div>

                <div className="flex flex-col gap-[18px] pt-[20px] w-full">
                  <div className="flex flex-col gap-[4px] w-full">
                    <h6 className="text-blackDark font-medium">{t("auth.form.field.password")}</h6>
                    <div className={`rounded-[6px] border border-[#D7D8DD] h-[35px] xlx:h-[40px] w-full relative flex items-center ${passFcs ? "bg-white" : "hover:bg-white bg-[#F5F5F5]"}`}>
                      <input className="rounded-[6px] bg-transparent w-full text-blackDull text-sm leading-snug h-full pl-[15px] xlx:pl-[17px] pr-[40px] placeholder:text-blackDull" type={hide ? "password" : "text"} name="password" placeholder={t("auth.form.field.password")} value={formik?.values?.password} onBlur={(e: any) => { formik?.handleBlur(e); setPassFcs(false) }} onChange={formik?.handleChange} autoComplete="off" onFocus={() => setPassFcs(true)} />

                      <div className="absolute right-[10px] top-[10px] xlx:top-[12px] cursor-pointer" onClick={handlePassword}>
                        {hide ? <CheckEye /> : <Eye />}
                      </div>
                    </div>
                    {formik?.touched?.password && formik?.errors?.password && <div className="flex gap-[6px] items-start"><Warn />
                      <span className="text-danger text-xs font-medium">{formik?.errors?.password}</span>
                    </div>}
                  </div>

                  <div className="flex flex-col gap-[4px] w-full">
                    <h6 className="text-blackDark font-medium">{t("auth.form.field.confirm_password")}</h6>
                    <div className={`rounded-[6px] border border-[#D7D8DD] h-[35px] xlx:h-[40px] w-full relative flex items-center ${cnfPassFcs ? "bg-white" : "hover:bg-white bg-[#F5F5F5]"}`}>
                      <input className="rounded-[6px] bg-transparent w-full text-blackDull text-sm leading-snug h-full pl-[15px] xlx:pl-[17px] pr-[40px] placeholder:text-blackDull" type={hide1 ? "password" : "text"} name="password2" placeholder={t("auth.form.field.confirm_password")} value={formik?.values?.password2} onBlur={(e: any) => { formik?.handleBlur(e); setCnfPassFcs(false) }} onChange={formik?.handleChange} autoComplete="off" onFocus={() => setPassFcs(true)} />

                      <div className="absolute right-[10px] top-[10px] xlx:top-[12px] cursor-pointer" onClick={handlePassword1}>
                        {hide1 ? <CheckEye /> : <Eye />}
                      </div>
                    </div>
                    {formik?.touched?.password2 && formik?.errors?.password2 && <div className="flex gap-[6px] items-start"><Warn />
                      <span className="text-danger text-xs font-medium">{formik?.errors?.password2}</span>
                    </div>}
                  </div>
                </div>

                <SecondaryBtn title="Submit" css="w-full flex items-center justify-center uppercase !rounded-[10px] mt-[30px] font-bold mb-[140px] xlx:mb-[136px]" onClick={formik?.handleSubmit} disabled={submit} />
              </form>

              <div className="absolute top-0 left-0 right-0 bottom-0 flex items-end justify-end">
                <img src={require("../../../assets/images/reset-password-bg.png")} alt="create-new-password-bg" className="rounded-b-[15px] w-full" />
              </div>
            </div>
          </div>
        </div >}
    </>
  );
};

export default NewPassword;