/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { Car, CloseBtn, RightArr1, Station, Walk } from "../../common/svg/AllSvg";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryBtn from "../../common/form/PrimaryBtn";

const TourDetailPopup = () => {
  const { t } = useTranslation('common');
  const tourOpen: boolean = useSelector((state: any) => state.data.tourOpen);
  const tourDetail: any = useSelector((state: any) => state.data.tourDetail);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const ref: any = useRef();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch(dataActions.setTourOpen(false));
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  document.onkeydown = function (evt: any) {
    if (evt.keyCode === 27) {
      dispatch(dataActions.setTourOpen(false))
    }
  };

  return (
    <>
      {tourOpen &&
        <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
          <div className="flex justify-center items-center min-h-screen">
            <div className="m-[15px] xlx:mx-auto w-full xlx:w-[1370px] h-auto relative bg-white rounded-[10px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)] p-[20px]" ref={ref}>

              <div className="w-full flex justify-between gap-[15px] xs:px-[10px]">
                <h2 className="text-primaryText font-bold">{tourDetail?.name}</h2>

                <button className="w-[23px] h-[23px] rounded-full" type="button" onClick={() => {
                  dispatch(dataActions.setTourOpen(false))
                }}>
                  <CloseBtn />
                </button>
              </div>

              <div className="flex items-center w-full gap-[15px] xlx:gap-[20px] mt-[15px] xlx:mt-[20px] mmm:h-[550px] xlx:h-[570px] mmm:flex-row flex-col">
                <div className="w-full xl:w-[545px] h-full bg-white rounded-[15px] shadow-[0px_2px_12px_0px_rgba(0,0,0,0.15)] p-[20px] xlx:px-[25px] xlx:pt-[40px] xlx:pb-[20px] flex flex-col justify-between mmm:order-1 order-2">
                  <div className="flex flex-col gap-[10px] xlx:gap-[15px]">
                    <h3 className="text-primaryText font-bold">{t("passenger.tours.about")}</h3>
                    <h4 className="text-primaryText font-medium">{tourDetail?.description}</h4>
                  </div>

                  <div className="flex flex-col gap-[15px] xlx:gap-[20px] mt-[15px] xlx:mt-[20px]">
                    <button className="h-[40px] xlx:h-[45px] bg-[#D9D9D9] bg-opacity-[0.2] rounded-[10px] flex items-center justify-between gap-[15px] px-[15px] xlx:pl-[17px] xlx:pr-[34px]" onClick={() => { dispatch(dataActions.setTourOpen(false)); !!params?.lang ? navigate(`/${params?.lang}/station/${tourDetail?.station?.code}`) : navigate(`/station/${tourDetail?.station?.code}`); }}>
                      <div className="flex items-center gap-[8px] xlx:gap-[10px] w-full justify-start">
                        <Station className="fill-blackDull min-w-[15px] xlx:min-w-[17px] xlx:w-[17px] xlx:h-[20px] w-[15px] h-[18px]" />
                        <h5 className="text-blackDull font-bold">{tourDetail?.station?.name}</h5>
                      </div>

                      <RightArr1 />
                    </button>

                    <div className="border-t border-b border-primary border-opacity-[0.2] py-[10px] xlx:py-[13px] px-[10px] xlx:px-[15px] flex flex-col gap-[8px]">
                      <h6 className="font-semibold text-blackDull">{t("passenger.tours.dis")}</h6>
                      <div className="flex items-center gap-[15px]">
                        <div className="flex items-center gap-[10px]">
                          <Walk className="min-w-[14px] w-[14px] h-[22px]" />
                          <h5 className="text-blackDull font-bold uppercase">{!!tourDetail?.estimated_walking_time_min ? tourDetail?.estimated_walking_time_min : "0"} {t("passenger.home.fare.detail.min")}</h5>
                        </div>

                        <div className="flex items-center gap-[10px]">
                          <Car className="min-w-[19px] w-[19px] h-[19px]" />
                          <h5 className="text-blackDull font-bold uppercase">{!!tourDetail?.distance_from_metro ? tourDetail?.distance_from_metro : "0"} {t("passenger.home.fare.detail.km")}</h5>
                        </div>
                      </div>
                    </div>

                    <div className="mt-[5px] w-full">
                      <PrimaryBtn title={t("passenger.home.banner.trip_planner.form.plan_journey")} onClick={() => { dispatch(dataActions.setTourOpen(false)); !!params?.lang ? navigate(`/${params?.lang}/map?to=${tourDetail?.station?.code}&toName=${tourDetail?.station?.name}&toType=station&date=${new Date()?.toString()}`) : navigate(`/map?to=${tourDetail?.station?.code}&toName=${tourDetail?.station?.name}&toType=station&date=${new Date()?.toString()}`); }} css="!w-full" />
                    </div>
                  </div>

                </div>

                <div className="w-full xl:w-[calc(100%-565px)] h-full mmm:order-2 order-1 min-h-[200px]">
                  <img src={tourDetail?.photograph_mapping?.file} alt={tourDetail?.name} className="w-full h-full min-h-[200px] rounded-[15px]" />
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  );
};

export default TourDetailPopup;