const PageLoader = () => {
    return (
        <div className="w-full flex items-center justify-center py-[100px] xlx:py-[145px]">
            <h1 className="text-primary font-black text-2xl max-w-[200px] text-center flex items-center uppercase gap-[2px]">Loading
                <div className="loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </h1>
        </div>
    );
};

export default PageLoader;