import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CloseBtn, ImpNoticeIcon } from "../../../common/svg/AllSvg";
import { dataActions } from "../../../../shared/store/data-Slice";
import SecondaryBtn from "../../../common/form/SecondaryBtn";
import { useTranslation } from "react-i18next";

const ImpNotice = () => {
    const impNotice: any = useSelector((state: any) => state.data.impNotice);
    const { t } = useTranslation('common');
    const dispatch = useDispatch();

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setImpNotice(false))
        }
    };

    const handleLink = (item: any) => {
        dispatch(dataActions.setWebOpen(true));
        dispatch(dataActions.setLink(item));
    }

    return (
        <>
            {impNotice &&
                <div className="fixed inset-0 z-40 overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[rgba(171,171,171,0.70)] backdrop-blur-[10px]" onClick={() => dispatch(dataActions.setImpNotice(false))}
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative m-[15px] mm:mx-auto bg-white rounded-[10px] w-full md:w-[783px] h-[500px] xlx:h-[615px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12))]">
                            <div className="w-full flex items-center justify-between py-[18px] px-[22px]">
                                <div className="flex items-center gap-[11px]">
                                    <ImpNoticeIcon />
                                    <h4 className="text-danger font-bold">{t("recruitment.home.disclaimer")}</h4>
                                </div>
                                <button className="w-[23px] h-[23px] rounded-full" autoFocus={true} onClick={() => dispatch(dataActions.setImpNotice(false))}>
                                    <CloseBtn />
                                </button>
                            </div>

                            <div className="w-full h-[calc(100%-70px)] flex flex-col justify-between rounded-b-[10px]">
                                <div className="h-full overflow-y-scroll w-full flex flex-col gap-[24px] px-[13px]">
                                    <div className="bg-[#D9D9D9] bg-opacity-[0.21] px-[31px] py-[20px]">
                                        <h5 className="font-bold text-blackDull">Beware of Fake Recruitment Offers in Mumbai Metro</h5>

                                        <h6 className="font-semibold text-blackDull pt-[20px]">It has come to notice that some unscrupulous elements are trying to deceive candidates/ public by false promises of securing for them a job in MMRC either through influence or by use of unfair/unethical means.
                                        </h6>

                                        <h6 className="font-semibold text-blackDull pt-[20px]">Please be advised of the following:</h6>

                                        <ul className="ml-[25px]">
                                            <li className="font-semibold text-blackDull text-sm leading-snug list-disc">Recruitment process in MMRC is fully computerized and the selection is purely based on the merit of the candidates.
                                            </li>

                                            <li className="font-semibold text-blackDull text-sm leading-snug list-disc">MMRC has not appointed any agent(s) or recruitment agency(s) for recruitment on its behalf. All recruitment related notifications are uploaded on MMRC's official website: <Link className="text-primary hover:underline focus:underline font-medium text-sm leading-snug" to="https://www.mmrcl.com/" target="_blank" onClick={(e) => { handleLink("https://www.mmrcl.com/"); e.preventDefault(); }}>https://www.mmrcl.com/</Link> only.</li>

                                            <li className="font-semibold text-blackDull text-sm leading-snug list-disc">Public/Candidates are advised not to fall prey to claims made by unscrupulous persons/agencies.</li>

                                            <li className="font-semibold text-blackDull text-sm leading-snug list-disc">Giving or taking bribe is an offence.</li>

                                            <li className="font-semibold text-blackDull text-sm leading-snug list-disc">Candidates found indulging in unfair means will he disqualified and shall be liable to legal action against them.</li>
                                        </ul>

                                        <h6 className="font-semibold text-blackDull pt-[20px]">In case anyone comes across any such unscrupulous persons/ agencies, please inform the nearest Police Station, with all possible details, so that appropriate action could be taken by the Police.
                                        </h6>

                                        <h6 className="font-semibold text-blackDull pt-[20px]">BE A PART OF THE MISSION FOR FAIR & TRANSPARENT RECRUITMENT PROCESS AND JOIN MMRC WITH PRIDE.
                                        </h6>

                                        <h6 className="font-semibold text-blackDull pt-[20px]">MMRC, Tel. Nos.: <span className="text-primary font-medium">022-26575155/022-26575164</span>
                                        </h6>
                                    </div>
                                </div>

                                <div className="px-[31px] flex items-end justify-end py-[24px]">
                                    <SecondaryBtn title={t("recruitment.home.understood")} css="px-[46px] font-semibold" onClick={() => dispatch(dataActions.setImpNotice(false))} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default ImpNotice;